import request from '@/utils/request'



export const getSetupFileList = (params: {}) =>
    request({
        // 获取安装文件列表
        url: '/v1/setupFileList',
        method: 'GET',
        params
    })

export const addSetupFile = (data: any) =>
    request({
        // 添加/更新安装文件
        url: '/v1/setupFile',
        method: 'POST',
        data
    })

export const querySetupFile = (fileId: string) =>
    request({
        //  获取安装文件by fileId
        url: `/v1/setupFile/${fileId}`,
        method: 'GET'
    })

export const deleteSetupFile = (fileId: string) =>
    request({
        // 删除学校by schoolId
        url: `/v1/setupFile/${fileId}`,
        method: 'DELETE'
    })


