
































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import * as SetupFileApi from "@/api/setupFile";
import { SetupFile } from "@/tool/_class";
import { uuid } from "@/tool";
import * as _ from "lodash";
@Component({
  name: "CreateSchool",
  components: {
    cardBox
  }
})
export default class extends Vue {
  private cascadeAreaId: string[] = [];
  private ruleForm: SetupFile = new SetupFile();
  rules = {

    productVersion: [
      { required: true, message: "请输入版本", trigger: "change" }
    ],
    readme: [{ required: true, message: "请输入自述", trigger: "change" }],
    releaseNotes: [{ required: true, message: "请输入发行说明", trigger: "change" }],

  };

  private areas: any[] = [];
  private pageType: string = "";
  private title: string = "";
  private get type(): string {
    if (this.pageType == "add") {
      this.title = "创建升级";
      return "立即创建";
    }
    if (this.pageType == "edit") {
      this.title = "编辑升级";
      return "保存";
    }
    return "立即创建";
  }
  submitForm() {
    (this as any).$refs.ruleForm.validate((valid: any) => {
      if (valid) {
        SetupFileApi.addSetupFile(this.ruleForm).then((res: any) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "创建成功!"
            });
          } else {
            this.$message({
              type: "warning",
              message: res.msg
            });
          }
        });
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  doGoBackClick() {
    this.$router.go(-1);
  }
  resetForm(formName: string | number) {
    (this as any).$refs.ruleForm.resetFields();

  }





  private async __init() {


    if (this.$route.query.fileId) {
      const { data } = await SetupFileApi.querySetupFile(this.$route.query.fileId as string);
      this.ruleForm = data;

    }
    this.pageType = this.$route.query.type as string;
  }


  mounted() {
    this.__init();
  }

}
